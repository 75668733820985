// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./FqQDnKsTi-0.js";

const cycleOrder = ["dqheJBxsS", "hH7uncp1V"];

const variantClassNames = {dqheJBxsS: "framer-v-1f1ons8", hH7uncp1V: "framer-v-1ghexo6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "dqheJBxsS", "Variant 2": "hH7uncp1V"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dqheJBxsS"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "dqheJBxsS", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter57bn69 = activeVariantCallback(async (...args) => {
setVariant("hH7uncp1V")
})

const onMouseLeave64vdmo = activeVariantCallback(async (...args) => {
setVariant("dqheJBxsS")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PGCnd", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({hH7uncp1V: {href: {webPageId: "H0aIhqLwv"}}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-1f1ons8", className)} framer-1l9niu5`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"dqheJBxsS"} onMouseEnter={onMouseEnter57bn69} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, ...style}} variants={{hH7uncp1V: {backgroundColor: "rgb(0, 0, 0)"}}} {...addPropertyOverrides({hH7uncp1V: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave64vdmo}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center"}}>Aké sú ceny ?</motion.p></React.Fragment>} className={"framer-1f640ee"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"qdsNKgToK"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} variants={{hH7uncp1V: {"--extracted-r6o4lv": "rgb(255, 255, 255)"}}} verticalAlignment={"center"} withExternalLayout {...addPropertyOverrides({hH7uncp1V: {children: <React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Aké sú ceny ?</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PGCnd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PGCnd .framer-1l9niu5 { display: block; }", ".framer-PGCnd .framer-1f1ons8 { height: 53px; overflow: hidden; position: relative; width: 212px; will-change: var(--framer-will-change-override, transform); }", ".framer-PGCnd .framer-1f640ee { flex: none; height: 53px; left: 0px; position: absolute; top: 0px; white-space: pre-wrap; width: 212px; word-break: break-word; word-wrap: break-word; }", ".framer-PGCnd.framer-v-1ghexo6 .framer-1f1ons8 { text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 212
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hH7uncp1V":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerFqQDnKsTi: React.ComponentType<Props> = withCSS(Component, css, "framer-PGCnd") as typeof Component;
export default FramerFqQDnKsTi;

FramerFqQDnKsTi.displayName = "ceny c";

FramerFqQDnKsTi.defaultProps = {height: 53, width: 212};

addPropertyControls(FramerFqQDnKsTi, {variant: {options: ["dqheJBxsS", "hH7uncp1V"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerFqQDnKsTi, [])